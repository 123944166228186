/* @import '../../path/to/node_modules/react-tabs/style/react-tabs.scss'; */
.page {
  display: flex;
}

.container {
  left: 0;
  right: 0;
  border-radius: 10px;
}

.heading {
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-top: 1rem;
}
.img-holder {
  border: 3px solid black;
  border-radius: 75px;
  margin-top: 1rem;
  width: 150px;
  height: 150px;
}
.img {
  object-fit: cover;
}

#input[type="file"] {
  display: none;
}

.image-upload {
  margin: auto;
  background: linear-gradient(180deg, #f74d58 0%, #e93944 54.91%, #de2834 100%);
  box-shadow: 2px 3px 24px rgba(226, 226, 226, 0.22);
  border-radius: 60px;
  width: 126px;
  height: 46px;
  padding: 10px;
  padding-left: 15px;
}

.tab-on {
  outline: none;
  width: 100%;
  height: auto;
}

.tab-on ::after {
  color: #de2834;
  border-bottom: 3px solid #de2834;
  border-top: none;
  border-left: none;
}

.nav-tabs .nav-item.show .nav-link {
  color: #de2834;
  border: none;
  border-bottom: 3px solid #de2834;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  border-bottom: #de2834;
  border: none;
}

.remove-button {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 2px 3px 24px rgba(226, 226, 226, 0.22);
  border-radius: 60px;
}

tabs .default {
  border-bottom: 1px solid #de2834;
}

.react-tel-input .form-control {
  width: 100% !important;
}
