* {
  box-sizing: border-box;
}


.text-input {
  background: #ffffff;
  outline: #de2834;
  border: 1px solid #de2834;
  border-color: gray;
  box-shadow: 2px 3px 24px rgba(226, 226, 226, 0.22);
  border-radius: 10px;
}

.text-input-error {
  border-color: red;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  /* or 187% */

  text-align: center;

  color: #646464;
}

#forgot-password {
    background: linear-gradient(180deg, #F74D58 0%, #E93944 54.91%, #DE2834 100%);
    border: 1px solid #DE2834;
    box-shadow: 2px 3px 28px rgba(222, 40, 52, 0.45);
    border-radius: 60px;
    width: 200px;
    color : #ffff;
    height: 40px;
}
