#login-button {
    background: linear-gradient(180deg, #f74d58 0%, #e93944 54.91%, #de2834 100%);
    border: 2px solid #de2834;
    width: 180px;
    box-shadow: 2px 3px 28px rgba(222, 40, 52, 0.45);
    border-radius: 60px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    text-transform: capitalize;
    color: #ffffff;
    margin-top: 30px;
    padding-bottom: 5px;
  }
  
  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    text-transform: capitalize;
    color: #757575;
  }
  .text-input button {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    /* identical to box height */
  
    text-decoration-line: underline;
    text-transform: capitalize;
  
    color: #de2834;
  }


  .capitalized{
    text-transform: none;
  }