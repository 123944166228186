.img-holder {
  border: 3px solid black;
  border-radius: 75px;
  margin-top: 1rem;
  width: 150px;
  height: 150px;
}
.dropped {
  left: -65px !important;
}
.mainDropped {
  margin-top: 80px !important;
}
.dropdown-menu {
  right: 0px !important;
  left: auto !important;
  transform: translate3d(0px, 38px, 0px) !important;
}