* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.container {
}

.text-input {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 2px 3px 24px rgba(226, 226, 226, 0.22);
}

.text-input-error {
  border-color: red;
  font-size: 12px;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
  font-size: 12px;
}

#register-button {
  background: linear-gradient(180deg, #f74d58 0%, #e93944 54.91%, #de2834 100%);
  border: 2px solid #de2834;
  width: 180px;
  box-shadow: 2px 3px 28px rgba(222, 40, 52, 0.45);
  border-radius: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;
  color: #ffffff;
  padding-bottom: 5px;
  padding: 8px;
  margin:10px
}

#otp-button {
  background: #fff1f1;
  border: 1px solid #f35f69;
  box-shadow: 2px 3px 26px rgba(235, 57, 68, 0.1);
  border-radius: 8px;
  color: #f35f69;
  font-size: 1rem;
}

#singpass-button {
  background: #ffeeef;
  border-radius: 15px;
  margin-top: -10px;
}

#terms input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
  width: 20px;
  height: 25px;
  outline: #de2834 !important;
  border: 1px solid #de2834 !important;
}
.input-check {
  outline: #de2834 !important;
  border: 2px solid #de2834 !important;
  background-color: #de2834;
  accent-color: #de2834;
}

.capitalized {
  text-transform: none;
}
/* }{
  background-color :rgba(222, 40, 52, 0.45);
  color :#de2834;
  border :1px solid black
} */
