#cardBody {
  background-color: #bad9db;
  float: right;
  width: 6.25rem !important;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

#cardBody1 {
  background-color: #fff;
  border-radius: 1.25rem;
  margin-top: .625rem;
  padding-left: 0;
  padding-right: 10;
  padding-bottom: 15;
  flex-direction: row;
}

#profile {
  float: "right";
  width: "9.375rem" !important;
  height: "1.875rem";
  border-radius: ".3125rem";
}

h1 {
  font-weight: "800";
  font-size: "1.875rem";
  padding: "1.25rem";
}

#Load-button {
  background: linear-gradient(180deg, #f74d58 0%, #e93944 54.91%, #de2834 100%);
  border: .125rem solid #de2834;
  box-shadow: .125rem .1875rem 1.75rem rgba(222, 40, 52, 0.45);
  border-radius: 3.75rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.4375rem; /* identical to box height */
  text-transform: capitalize;
  width: 6.25rem;
  color: #ffffff;
}
/* index.css */

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  border: 0rem solid #dddddd;
  text-align: left;
  padding: .3125rem;
}
/* tr:nth-child(even) {
  background-color: #dddddd;
} */
tr{
  border-bottom:.0625rem solid #dddddd;
}

tr:hover {
  background-color: #f7eeec;
}
#page-Button {
  background: linear-gradient(180deg, #f74d58 0%, #e93944 54.91%, #de2834 100%);
  border: .125rem solid #de2834;
  border-radius: .3125rem;
  color: #ffffff;
  display: flex;
  align-self: center;
  height: 1.875rem !important;
  width: 1.875rem !important;
  text-align: center !important;
  margin-left: .625rem;
}
#inactive-Button {
  border: .0625rem solid #dddddd;
  border-radius: .3125rem;
  display: flex;
  align-self: center;
  height: 1.875rem !important;
  width: 1.875rem !important;
  text-align: center !important;
  margin-left: .625rem;
  background-color: #fff;
  justify-content: center;
  align-items: center;
}
.all-buttons {
  display: flex;
  justify-content: center !important;
  align-self: center !important;
}