* {
  box-sizing: border-box;
}

.myCon {
  justify-content: center;
  text-align: center;
}

.myrow {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mycol1 {
  background-color: #fff;
  height: 100%;
  width: 100%;
}

.mycol2 {
  height: 100%;
  width: 100%;
  padding: 80px;
}

.text-input {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 2px 3px 24px rgba(226, 226, 226, 0.22);
  border-radius: 15px; 
}

.text-input-error {
  border-color: red;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  /* or 187% */

  text-align: center;

  color: #646464;
}

#forgot-password {
  background: linear-gradient(180deg, #f74d58 0%, #e93944 54.91%, #de2834 100%);
  border: 1px solid #de2834;
  box-shadow: 2px 3px 28px rgba(222, 40, 52, 0.45);
  border-radius: 60px;
  width: 200px;
  color: #ffff;
  height: 40px;
 
}

#ul-styles {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  /* identical to box height */
  list-style: none;
  color: #585858;
}

