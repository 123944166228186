* {
  box-sizing: border-box;
}

.text-input {
  background: #ffffff;
  outline: #de2834;
  border: 1px solid;
  border-color: #eeeeee;
  box-shadow: 2px 3px 24px rgba(226, 226, 226, 0.22);
  border-radius: 10px;
}

.text-input-error {
  border-color: red;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

#login-button {
  background: linear-gradient(180deg, #f74d58 0%, #e93944 54.91%, #de2834 100%);
  border: 2px solid #de2834;
  width: 180px;
  box-shadow: 2px 3px 28px rgba(222, 40, 52, 0.45);
  border-radius: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;
  color: #ffffff;
  padding-bottom: 5px;
  padding: 8px;
  margin-bottom: -20px;
}

span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-transform: capitalize;
  color: #757575;
}
.text-input button {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  /* identical to box height */

  text-decoration-line: underline;
  text-transform: capitalize;

  color: #de2834;
}
