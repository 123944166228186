.titlle {
  color: #de2834 !important;
  font-weight: 500;
}
.pp {
  padding: 2px;
  font-family: Roboto;
  font-size: medium;
}
body {
    /* overflow-y: hidden;  */
    /* overflow-x: hidden; Hide horizontal scrollbar */
  }