* {
  font-family: Poppins;
}
/* 
.file-upload {
  border-color: dashed 2px #de2834;
  height: 200px;
} */

.form-input {
  box-shadow: 2px 3px 24px 0px #dadada21;
}

span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  /* identical to box height */

  text-transform: capitalize;

  color: #757575;
}

#next-button {
  background: linear-gradient(180deg, #f74d58 0%, #e93944 54.91%, #de2834 100%);
  border: 1px solid #de2834;
  box-shadow: 2px 3px 28px rgba(222, 40, 52, 0.45);
  border-radius: 60px;
  color: #ffff;
  width: 100px;
}
