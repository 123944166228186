* {
  font-family: Poppins;
}
.form-input {
  box-shadow: 2px 3px 24px 0px #dadada21;
}
/* .file-upload {
  background-color: #fae3e3;
  border: 2px solid #de2834 !important;
  border-style: dashed !important;
  height: 100px !important;
} */
/* .cXBrtO span {
  font-size: 0px;
} */
#login-button {
  background: linear-gradient(180deg, #f74d58 0%, #e93944 54.91%, #de2834 100%);
  border: 2px solid #de2834;
  width: 180px;
  box-shadow: 2px 3px 28px rgba(222, 40, 52, 0.45);
  border-radius: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;
  color: #ffffff;
  padding-bottom: 5px;
  cursor: pointer;
}
#title {
  font-style: normal;
  font-size: 17px;
  text-align: left;
  color: #1f1f1f;
}

/* .cXBrtO span {
  font-size: 0px;
}
.fVfcRr > span {
  font-size: 0px;
  color: #666;
}
.fVfcRr > span {
  font-size: 0px !important;
} */

/* .docName {
  color: #666;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 85% !important;
}
.docSize {
  position: absolute;
  color: #666;
  top: -8px;
  right: 10%;
}
.docType {
  position: absolute;
  color: #666;
  top: -8px;
  left: 0px;
} */
/* .document {
  position: relative;
  bottom: 71px;
  text-decoration: underline;
  margin-left: 45px;
}

.kaZkLh > span {
  font-size: 0px;
}

.kaZkLh > span {
  font-size: 12px;
  color: red;
  top: 26px;
  position: relative;
} */


.file-upload {
  background-color: #fae3e3;
  border: 2px solid #de2834 !important;
  border-style: dashed !important;
  height: 100px !important;
  width: 100% !important;
}

/* .cXBrtO span {
  font-size: 0px;
}
.fVfcRr > span {
  font-size: 0px;
  color: #666;
}
.fVfcRr > span {
  font-size: 0px !important;
} */

.docName {
  color: #666;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  width: 85% !important;
  
  position: relative;
  left: 15px;
}
.docSize {
  position: absolute;
  color: #666;
  /* top: -8px; */
  right: 10px;
}
.docType {
  position: absolute;
  color: #666;
  top: -8px;
  left: 0px;
}
.document {
  position: relative;
  bottom: 37px;
  text-decoration: underline;
  margin-left: 27px;
  display: flex;
    flex-direction: row;
}
/* .kaZkLh > span {
  font-size: 0px;
} */
.maxSize {
  position: relative;
  color: #666;
  bottom: 51px;
  font-size: 13px;
  text-align: right;
}
@media screen and (max-width: 1025px){
  .docName {
    width: 75% !important;
  }
}
.kiHXWb {
  max-width: none !important;
}

.fwCQWG {
  max-width: 100% !important;
  min-width: 100% !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}
