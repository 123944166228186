* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Poppins";
}

.container {
  display: flex;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  left: 0;
}
main {
  width: 100%;
  background-color: #ffff;
}
.sidebar {
  height: 100vh;
  width: 400px;
  transition: all 0.5s;
  background: #ffffff;

  border-radius: 0px 30px 30px 0px;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 20px;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 15px;
  margin-left: 50px;
}
.link {
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 10px;
  /* or 522% */
  text-transform: capitalize;
  color: black;
  transition: all 0.5s;
}
a{
  color: #de2834;
}
.link_text:hover {
  /* background: lightskyblue; */

  transition: all 0.5s;
}
.active {
  color: #de2834;
  border-left: 3px solid #de2834;
}
.icon,
.link_text {
  font-size: 20px;
  padding: 5px;
}
.sub-options {
  line-height: 11px;
  color:black;
  margin-left:-50px
}
.submenu{
  line-height: 40px;
 border-left: none;
  font-size: 20px;
  color: black;
}

.submenu .active{
  border-left: none;
  color: #de2834;
}