

.text-input {
    border-color: #de2834;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }
  
  .text-input-error {
    border-color: red;
  }
  
  .input-feedback {
    color: red;
    margin-top: 0.25rem;
  }
  